.modalContainer {
  max-width: 505px;
  width: 100%;

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 16px;

    .description {
      color: #212121;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      .learnMore {
        text-decoration: underline;
        color: #18397a;
      }
    }

    .buttonGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .googleBtn {
        display: flex;
        gap: 12px;
        align-items: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        @media screen and (max-width: 500px) {
          padding-top: 4px !important;
          padding-bottom: 4px !important;
        }

        span {
          height: 40px;
        }

        .signUpText {
          display: block;

          @media screen and (max-width: 500px) {
            display: none;
          }

          color: #18397a;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px;
          text-transform: uppercase;
        }
      }
    }
  }
}
