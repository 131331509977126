@import 'styles/globals';
@import 'styles/mixins';

#root {
  height: 100%;
}

.adminPageContentBox {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  padding-top: 20px;
  width: 90%;
  margin-bottom: 30px;
  background: none;
  box-shadow: none;
  cursor: unset !important;

  @include for-mobile-max_767 {
    flex-wrap: wrap;
    width: 100%;
  }
}

.blur-content {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.MuiAlert-message {
  max-width: 95vw !important;
  word-break: break-all !important;
}

/* editor override styles */

.ce-settings {
  left: -130px !important;
  z-index: 50;
}

.ce-render-block {
  margin: 10px 0;
}

.ce-paragraph--center {
  text-align: center;
}

.ce-paragraph--left {
  text-align: left;
}

.ce-paragraph--right {
  text-align: right;
}

.codex-editor__redactor {
  padding-bottom: 270px !important;
}

.image-tool {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-tool--withBorder {
  border: 1px solid var(--border-color);
}

.image-tool--withBackground {
  padding: 15px;
  background: var(--bg-color);
}

.image-tool--stretched {
  width: 100%;
}

.image-tool--stretched > img {
  width: 100%;
}

.image-tool--empty {
  align-items: unset !important;
}

.menuItem__title {
  display: none;
}

// Icons colors style
.icon-blue-fill {
  svg > path {
    fill: #18397a !important;
  }
}

.icon-blue-stroke {
  svg > path {
    stroke: #18397a !important;
  }
}

.icon-red-fill {
  svg > path {
    fill: #d32f2f !important;
  }
}

.icon-red-stroke {
  svg > path {
    stroke: #d32f2f !important;
  }
}

.icon-green-fill {
  svg > path {
    fill: #4caf50 !important;
  }
}

.icon-green-stroke {
  svg > path {
    stroke: #4caf50 !important;
  }
}

.icon-white-fill {
  svg > path {
    fill: #ffffff !important;
  }
}

.icon-white-stroke {
  svg > path {
    stroke: #ffffff !important;
  }
}

.ant-btn-color-dangerous.ant-btn-variant-outlined {
  color: #d32f2f !important;
  border-color: #d32f2f !important;
}
