.body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nestedSelect {
  z-index: 10;

  > div {
    border: none !important;
    padding: 0 !important;
    height: 26px !important;
    border-radius: 10px;
  }
}

.modalInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.infoItem,
.bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.infoItem {
  padding: 0 5px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.bottom {
  margin-top: 16px;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
