.dotIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: flex-start;
}

.menuItemWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0;

  &:first-child {
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    margin: 0;
  }
}

.shareBtn,
.trashBtn {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span > svg {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
}
