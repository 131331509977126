.cartContainer {
  display: flex;
  min-width: min-content;
  width: 415px;
  height: max-content;
  flex-direction: column;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
  align-content: center;
  padding: 16px 20px 10px 20px;
  margin-bottom: 16px;
}

.loaderWrapper {
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;

  // span {
  //   width: 40px !important;
  //   height: 40px !important;
  //   font-weight: 400px;
  //   color: #212121;
  // }
}

.cartHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;

  .cartHeaderTitle {
    display: flex;
    flex-direction: row;

    > p {
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
      color: #212121;
    }
  }
}

.cartItemsContainer {
  border-bottom: 1px solid #efefef;
  padding-bottom: 8px;
  margin-bottom: 8px;

  > p {
    font-size: 12px;
    font-weight: 400;
    color: #757575;
  }

  .cartItemsList > a {
    font-size: 12px;
    font-weight: 400;
    color: #757575;
    margin-top: 4px;
    cursor: pointer;
  }
}

.cartSummary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .cartInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    p {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      color: #212121;
    }

    .cartTotalProducts {
      display: flex;
      flex-direction: row;
      align-items: center;

      p:nth-child(1) {
        font-size: 16px;
        font-weight: 500;
        color: #4caf50;
      }

      p:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        color: #555555;
        margin-left: 8px;
      }
    }
  }
  .cartButtonStart {
    display: flex;
    align-items: center;
    justify-content: end;
    button {
      font-size: 12px !important ;
      font-weight: 600 !important;
      padding: 5px 20px !important;
    }
  }
  .cartButtonView {
    display: flex;
    align-items: center;
    justify-content: end;
    button {
      font-size: 12px !important ;
      font-weight: 600 !important;
      padding: 5px 20px !important;
    }
  }
}
