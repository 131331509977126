@import './theme';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-default;
  font-style: normal;
  font-weight: 600;
}

h1 {
  font-weight: bold;
  font-size: 36px;
  //line-height: 56px;

  @include screen-md {
    font-size: 40px;
    //line-height: 60px;
  }

  @include screen-lg {
    font-size: 64px;
    //line-height: 96px;
  }
}

h2 {
  font-size: 32px;
  //line-height: 48px;

  @include screen-md {
    font-size: 36px;
    //line-height: 56px;
  }

  @include screen-lg {
    font-size: 48px;
    //line-height: 72px;
  }
}

h3 {
  font-size: 28px;
  //line-height: 44px;

  @include screen-md {
    font-size: 32px;
    //line-height: 48px;
  }
  @include screen-lg {
    font-size: 40px;
    //line-height: 60px;
  }
}

h4 {
  font-size: 24px;
  //line-height: 36px;

  @include screen-md {
    font-size: 24px;
    //line-height: 36px;
  }
  @include screen-lg {
    font-size: 32px;
    //line-height: 48px;
  }
}

h5 {
  font-weight: 600;
  font-size: 20px;
  //line-height: 32px;

  @include screen-md {
    font-size: 20px;
    //line-height: 32px;
  }
  @include screen-lg {
    font-size: 24px;
    //line-height: 36px;
  }
}

h6 {
  font-weight: 600;
  font-size: 16px;
  //line-height: 24px;

  @include screen-md {
    font-size: 16px;
    //line-height: 24px;
  }
  @include screen-lg {
    font-size: 20px;
    //line-height: 32px;
  }
}

blockquote {
  border-left: 3px solid rgba(221, 221, 221, 0.5);
  font-style: italic;
  padding-left: 17px;
} /* #DDD at .5 strength*/
pre,
code {
  font-family: monaco, 'Courier New', Courier, monospace;
  font-size: 1em;
}
sup,
sub {
  font-size: 0.7em;
  line-height: 1em;
  vertical-align: baseline;
}
sup {
  position: relative;
  top: -0.4em;
}
sub {
  position: relative;
  top: 0.4em;
}

ol,
ul,
dd {
  margin-left: 1.5em;
}
ul {
  list-style: disc;
}
li ul,
li ol {
  margin-bottom: 0;
}
ol {
  list-style: decimal;
}
dt {
  font-weight: bold;
}

hr {
  margin: 1.3846em 0 1.308em 0;
  height: 0;
  border: 1px solid #888;
  border-width: 1px 0 0 0;
}
