@import 'styles/mixins';
@import '../../DigitalAssets/styles/digitalAssetsCommon.module';

.block {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 24px 0 #0000000a;
}

.page {
  @media (max-width: 500px) {
    padding: 0 !important;
  }
}

.tabs {
  width: 100%;
  height: 56px;

  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  .tabItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #212121;
    margin-top: 5px;
    padding: 0;

    @media screen and (max-width: 1340px) {
      margin: 0 10px;
    }
  }
  .activeItem {
    @include for-desktop-up-max_1500 {
      margin: 5px 10px;
    }
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #143471 !important;
  }

  button {
    text-transform: none !important;
  }
}

.unblockBtn {
  padding: 10px;
  cursor: pointer !important;
  color: #eb793f;
  font-weight: 600;
}

.idColumn {
  font-family: 'Montserrat', sans-serif !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rolesColumn {
  font-family: 'Montserrat', sans-serif !important;
  overflow: hidden;
  word-break: break-word;
  height: 100%;
  width: 140px;
}

.nameColumn,
.walletColumn {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 0;

  button {
    padding: 0;
  }
}

.walletColumn {
  flex-direction: row;
  justify-content: space-between;
}

.walletRow {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.tableContent {
  width: 100%;
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 20px;

  @include for-mobile-max_767 {
    padding: 24px 20px;
  }

  .tableTotal {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  .filters {
    @include for-desktop-up-max_1500 {
      flex-wrap: wrap;
      margin-top: 0;
    }

    @media screen and (max-width: 500px) {
      > div {
        width: 100%;
      }
    }

    display: flex;
    column-gap: 20px;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    .searchFilter {
      @media screen and (max-width: 500px) {
        width: 100% !important;
      }

      flex-shrink: 0;
      width: 200px;
      position: relative;
      height: 48px;

      .searchFilterIcon {
        position: absolute;
        width: 24px;
        left: 22px;
        top: 11px;
        z-index: 10;
      }

      .searchFilterInput {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-left: 56px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #efefef;
        border-radius: 12px;
        height: 100%;

        input {
          &::placeholder {
            font-weight: 400;
            font-size: 16px;
            color: #757575;
          }
        }
      }
    }

    .filterSelect {
      @media screen and (max-width: 500px) {
        width: 100% !important;
      }

      width: 145px;
      min-width: 130px;
      height: 48px;
      background: #ffffff;
      border-radius: 12px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      fieldset {
        border-color: #e0e3e7;
      }
    }
  }
}

.menuItem {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.filterSelectPlaceholder {
  font-weight: 400;
  font-size: 16px;
  color: #555555;
}

.pagination {
  justify-content: center;
}

.paginationItem {
  background: #ffffff;
  border: 1px solid #efefef !important;
  border-radius: 12px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.topMenuItem {
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-transform: capitalize;
}

.topSelect {
  line-height: 24px !important;
  margin-top: 12px !important;
  background: #ffffff !important;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px !important;
  width: 100% !important;
  height: 48px !important;
  border: none !important;

  span {
    font-family: 'Montserrat', sans-serif !important;
    color: #18397a !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
  }
}

.dataPicker,
.dataPicker > div {
  width: 340px;
  max-width: 340px;

  > div {
    min-width: 140px;
    width: 140px;
    flex-shrink: 0;

    > div {
      padding: 0 12px !important;

      button {
        padding: 10px 0 !important;

        &:hover {
          background: transparent !important;
        }
      }

      &:nth-child(1) {
        margin-right: 1px;

        @media screen and (max-width: 620px) {
          margin-right: -20px;
        }
      }
    }

    > div > div {
      padding: 0 !important;

      @media screen and (max-width: 620px) {
        > input {
          width: 85px !important;
        }
      }
    }
  }

  input {
    width: 90px !important;
    flex-shrink: 0;
    padding: 12px 0 !important;
  }

  @media screen and (max-width: 620px) {
    width: 100%;

    input {
      width: 80px;
    }
  }
}
