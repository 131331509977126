.navbarContent {
  z-index: 999;
  width: 100%;
  background-color: #fff;
  padding: 0 60px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);

  @media only screen and (max-width: 1500px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media only screen and (max-width: 480px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.desktopLinks {
  display: block;

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.mobileLinks {
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
  }
}

.headerMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
  padding-top: 14px;

  @media screen and (max-width: 900px) {
    justify-content: center;
    flex-wrap: wrap;
  }

  .headerMenuItem,
  .headerMenuDarkItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    cursor: pointer;
    color: #c4c4c4;
  }

  .connectWalletButton {
    border-radius: 4px;
    padding: 0 20px;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
  }
}

.modalConnectWalletTitle {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #212121;
}

.exitLink {
  height: 50%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #d32f2f;
}
.exitLinkForMenu {
  padding-left: 25px;
  height: 50%;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #d32f2f;
}

.forMobile {
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
.navbarPersonalArea {
  padding-bottom: 15px;

  @media only screen and (max-width: 767px) {
    padding-top: 25px;
  }
}

.mobileMenuRow {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 27px;
}

.navbarLogo {
  position: relative;
}
.logoImg {
  width: 80px;
  height: 80px;
}

.navbarLinks {
  display: flex;

  @media only screen and (max-width: 1660px) {
    flex-wrap: wrap;
  }

  z-index: inherit;
  align-items: center;
  column-gap: 4px;
  width: 100%;
  height: 50%;
  justify-content: center;
}

@media only screen and (max-width: 1660px) {
  .navbarLink {
    padding: 16px !important;
  }
}

.navbarLink {
  padding-left: 25px !important;
}

/* .navbarLinks .active {
  border: 1px solid black;
} */
.home[type='home']:checked ~ label {
  border: 1px solid black;
}

.navbarLogoSearchBox {
  display: flex;
  align-items: center;
  column-gap: 24px;
}
.navbarLinkImage {
  margin-right: 13px;
  transition: 0.2s;
}

.navbarLinktext {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
  color: #555555;
  white-space: nowrap;
  transition: 0.2s;
}
.mobileOption {
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    display: block;
  }
}

.forMobileMenu {
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    z-index: 9999;
    top: 70px;
    right: 0;
  }
}

@media screen and (max-width: 1700px) {
  .navbarLinks {
    margin-right: 100px;
  }
}

@media screen and (max-width: 1550px) {
  .navbarLinks {
    margin-right: 0;
  }
}

@media screen and (max-width: 1340px) {
  .navbarLinks {
    column-gap: 5px;
  }
}

@media screen and (max-width: 520px) {
  .mobileMenuRow {
    column-gap: 10px;
  }

  .navbarLogoSearchBox {
    column-gap: 12px;
  }

  .logoImg {
    width: 40px;
    height: 40px;
  }

  .navbarContent {
    height: 76px;
  }
}

.menuTitle {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #555555;
}

.menuIcon {
  height: 7px;
  width: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 0 !important;
}

.menuHeader {
  padding: 20px 20px 0 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .menuTitle {
    color: #212121;
  }
}
