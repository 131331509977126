@import '/src/styles/mixins';

.content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  overflow-y: scroll;
  overflow-x: hidden;
  //scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }

  @media only screen and (max-height: 740px) {
    overflow: scroll !important;
  }
}

.headerWrapper {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.pageContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pageContent {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;

  @include for-mobile-max_767 {
    flex-wrap: wrap;
  }
}
