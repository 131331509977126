.iframeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.iframe {
  margin-top: 10px;
  @media screen and (max-width: 530px) {
    width: 100%;
  }
}

.modalHeader {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.needModalBody {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  ul {
    margin-left: 30px;
  }
}

.acceptButton {
  padding: 12px 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 48px;
  margin-bottom: 10px;
}
