@import 'styles/mixins';
@import '../../styles/digitalAssetsCommon.module';

.TransactionDetailModal {
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.context {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
}

.header {
  margin-bottom: 20px;
}
