.editStatusModal {
  @media screen and (max-width: 1024px) {
    width: 78%;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }

  .modalTitle {
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .modalContent {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    .statusName {
      text-decoration: none;
      font-family: 'Montserrat', sans-serif !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }
    .disabledStatusName {
      color: #bdbdbd;
    }
  }

  .modalActions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .leftButton {
    padding: 0px 20px;
    border: 1px solid #18397a;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    color: #18397a;
  }

  .rightButton {
    padding: 0 20px;
    background: #18397a;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ffffff;
  }
}
