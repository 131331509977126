@import './theme';
@import './typography';
@import './fonts';

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  font-family: Montserrat, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  box-sizing: border-box;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-default;
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
