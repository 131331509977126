@import 'styles/mixins';
@import '../styles/digitalAssetsCommon.module';

.MainMenu {
  margin-top: 15px;

  button {
    text-transform: none !important;
  }
}
