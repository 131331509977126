// black colors
$black-10: #efefef;
$black-20: #cccccc;
$black-40: #999999;
$black-60: #666666;
$black-80: #333333;

// blue
$blue-default: #03a9f4;
$blue-hover: #67daff;
$blue-active: #007ac1;
$blue-light: #e0f4fd;

/* system colors */
$system-green: #4caf50;
$system-red: #f44336;
$system-yellow: #fdd835;

$role-expert: #cd5e3b;
$role-investor: #3c7097;
$role-business: #799429;
$role-user: #c99e31;

$disabled: $black-10;
$button-disabled: $blue-light;

// fonts
$font-default: Montserrat, sans-serif;

// Breakpoints.
$bp-sm: 640px;
$bp-md: 768px;
$bp-lg: 1024px;
$bp-xl: 1278px;
$bp-2xl: 1536px;

@mixin screen-sm {
  @media (min-width: $bp-sm) {
    @content;
  }
}

@mixin screen-md {
  @media (min-width: $bp-md) {
    @content;
  }
}

@mixin screen-lg {
  @media (min-width: $bp-lg) {
    @content;
  }
}

@mixin screen-xl {
  @media (min-width: $bp-xl) {
    @content;
  }
}

@mixin hide-up($breakpoint: $bp-sm) {
  @media (min-width: $breakpoint) {
    display: none;
  }
}

/* @mixin hide-down($breakpoint: $bp-sm) {
  @media (max-width: $breakpoint - 1) {
    display: none;
  }
} */
