.avaDiv {
  position: relative;
  width: 128px;
  height: 128px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.errorAvatar {
  border: 2px solid #d32f2f !important;
}

.errorMessage {
  color: #d32f2f;
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
}

.avaButton {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  background: rgba(0, 0, 0, 0.4) !important;
  backdrop-filter: blur(2px);
  pointer-events: visible;
  cursor: pointer;

  > span {
    flex-shrink: 0 !important;
  }

  > input {
    display: none !important;
  }
}
