@import 'styles/mixins';
@import './styles/digitalAssetsCommon.module';

.content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #e5e5e5;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.pageContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.8% 7%;

  @include for-mobile-max_767 {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }

  @include for-mobile-max_767 {
    flex-wrap: wrap;
  }
}

.forMobileMenu {
  @include for-tablet-landscape-min_1024 {
    display: none;
  }

  @include for-tablet-landscape-max_1024 {
    display: block;
  }
}

.contentBox {
  width: 100%;
  margin-bottom: 1.5% !important;
}

.middleContent {
  width: 100vw;
  max-width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;

  @include for-desktop-up-max_1200 {
    width: 100vw;
  }
}

.leftColumn {
  display: block;
  flex-direction: column;
  align-items: center;

  @include for-desktop-up-max_1200 {
    display: none;
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;

  @include for-desktop-up-max_1200 {
    display: none;
  }
}

.invite {
  margin-bottom: 4%;
}

.Breadcrumb {
}

@media screen and (max-width: 1024px) {
  .pageContent {
    width: 100%;
    padding: 0;
  }
}
