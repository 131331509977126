@import '../../../../../styles/mixins';
@import '../../../styles/digitalAssetsCommon.module';

.carbonTransactionDetailModal {
  @media screen and (max-width: 350px) {
    padding: 10px !important;
  }
}

.h1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #212121;
}

.row1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;
}

.row1_1 {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: flex-start;
  gap: 25px;
}

.col1_1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  > span:nth-child(1) {
    width: 110px !important;
  }
}

.certBlock {
  position: relative;
  width: 300px;
  height: 200px;
}

.certLoadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.certDownload {
  align-items: center;
  justify-content: center;
  background: #2b333f;
  opacity: 0.6;

  position: absolute;
  top: 0;

  border-radius: 5px;
}

.certDownloadSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b333f;
  opacity: 0.3;

  position: absolute;
  top: 400px;
  width: 300px;
  height: 30px;

  border-radius: 5px;
}

.certMainDownloadSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b333f;
  opacity: 0.3;

  position: absolute;
  top: 900px;
  width: 300px;
  height: 30px;

  border-radius: 5px;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.notFound {
  display: flex;
  font-size: 14px;
  padding: 20px;
  width: 300px;
  height: 200px;
}

@media screen and (max-width: 775px) {
  .сarbonTransactionDetailModal {
    width: 98%;
  }

  .row1 {
    flex-direction: column;
    gap: 20px;
  }
}
