@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../assets/fonts/montserrat/montserrat-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../assets/fonts/montserrat/montserrat-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../assets/fonts/montserrat/montserrat-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../assets/fonts/montserrat/montserrat-bold.ttf') format('truetype');
}
