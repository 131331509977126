@import 'styles/mixins';
@import '../../../styles/digitalAssetsCommon.module';

.modalBody {
  width: 100%;
  overflow-x: hidden;
}

.certificateCO2 {
  > img {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
  }
}

.notFound {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-bottom: 20px;
}

.certificateCO2_btn_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
