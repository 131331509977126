.page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  p {
    line-height: 24px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
}

.mainContainer {
  width: 100%;
  min-height: 400px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px 40px;

  @media screen and (max-width: 400px) {
    padding: 24px 20px;
  }
}

.mainContainerHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:nth-child(1) {
      font-size: 16px;
      font-weight: 600;
    }

    > button:nth-child(1) {
      margin-right: -5px;
    }
  }
}

.mainInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
}

.certImageBlock {
  display: block;
  max-width: 470px;
  max-height: 350px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  > img {
    height: auto;
    max-width: 470px;
    max-height: 350px;
    border-radius: 12px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
  }

  @media screen and (max-width: 860px) {
    max-width: 390px;
  }

  @media screen and (max-width: 780px) {
    max-width: 340px;
  }

  @media screen and (max-width: 700px) {
    max-width: unset;
  }
}

.certDataBlock {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.infoItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    font-size: 16px;
    font-weight: 500;
  }

  > span {
    font-size: 16px;
    font-weight: 400;
    text-align: right;
  }

  @media screen and (max-width: 700px) {
    > div,
    > span {
      font-size: 14px;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: #efefef;
  margin: 10px 0;
}

.bottomContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
  width: 100%;
}

.depositBlock {
  margin: 10px 0;
  border-radius: 12px;
  border: 1px solid #4caf50;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 20px;

    > span {
      width: fit-content;

      @media screen and (max-width: 350px) {
        width: 60px;
      }
    }

    @media screen and (max-width: 500px) {
      font-size: 16px;
    }
  }
}

.block {
  width: 100%;
  min-height: 200px;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  gap: 20px;

  @media screen and (max-width: 400px) {
    padding: 24px 20px;
  }
}

.offsetImage {
  width: 100%;
  max-width: 660px;
  box-shadow: 0px 4px 14px 0px #00000014;
  border-radius: 12px;
}

.tabs {
  button {
    text-transform: unset !important;
  }
}

.blockHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;
  }

  > h2,
  > div {
    font-size: 24px;
    font-weight: 600;

    @media screen and (max-width: 540px) {
      font-size: 20px;
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 540px) {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.blockText {
  width: 100%;
  font-size: 16px;
  font-weight: 400;

  > p {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
    color: #18397a;
    cursor: pointer;
  }
}

.blockFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @media screen and (max-width: 630px) {
    flex-direction: column-reverse;

    button,
    div {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.infoModalRoot {
  width: 900px;

  > div {
    &:nth-child(1) {
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 910px) {
    width: 100%;
  }
}

.infoModal {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 25px !important;
}

.modalInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 0;
}

.infoModalActions {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
}

.infoModalCertData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }
}

.infoModalCertDataRows {
  display: flex;
  flex-direction: column;
  gap: 10px;

  > p {
    font-size: 20px;
    font-weight: 600;
  }
}

.infoModalImage {
  img {
    border-radius: 12px;
    width: 300px;
    height: auto;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
  }
}

.infoModalTableContainer,
.infoModalTableContainerEmpty {
  margin: 16px 0;
  width: 100%;

  > p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  > div {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }
}

.infoModalTableContainerEmpty {
  > div {
    overflow-x: hidden !important;
    width: auto !important;
    max-width: unset !important;
  }
}

.infoModalNoTransactions {
  margin: 60px 0;
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.registerModalText {
  margin-bottom: 16px;
  line-height: 24px;

  > a {
    color: #18397a;
    text-decoration: underline;
  }

  > b {
    font-weight: 600;
  }
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.controlsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  > div {
    width: 50%;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;

    > div {
      width: 100% !important;
    }
  }
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.certLoadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
}

.registerList {
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 0 4px 16px 0 #0000000f;
    padding: 14px 32px;
    word-break: break-word;

    span {
      font-weight: 400;
      color: #757575;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
}

.nestedSelect {
  z-index: 10;

  > div {
    border: none !important;
    padding: 0 !important;
    height: 26px !important;
    border-radius: 10px;
  }
}

.offsetCertBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > div {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
  }
}

.registerSearchInput {
  width: 100%;

  > input {
    width: 100%;
  }
}
