@import 'styles/mixins';
@import '../styles/digitalAssetsCommon.module';

.DA {
  .MainMenu.TabMenu {
    color: #143471;

    .menuItem {
      p {
        color: #143471;
      }
    }
  }
}
