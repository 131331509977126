@import 'styles/mixins';
@import '../../styles/digitalAssetsCommon.module';

.config {
}

.confirm {
  margin-top: 10px;
}

.simplePaymentRow1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 10px;

  > span:nth-child(1) {
    font-weight: 500;
  }

  > span:nth-child(2) {
    margin-left: -7px;
  }
}

.fullWidthInput {
  width: 100%;

  > div {
    width: 100%;
  }
}

.simplePaymentRow2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.confirmDetailRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  gap: 30px;
}

.confirmDetailRow div:first-child {
  width: 205px;
}

.confirmDetailRow div:last-child {
  width: 486px;
}

@media screen and (max-width: 865px) {
  .confirm .confirmDetailRow {
    display: block;
  }
  .confirmDetailRow div:first-child {
    width: 100%;
  }

  .confirmDetailRow div:last-child {
    width: 100%;
  }

  .confirm .confirmDetailRow .recipientAddress {
    font-size: 9px;
  }
}
