.container {
  width: 100%;
  padding: 24px 40px;
  border-radius: 16px;
  gap: 20px;
  background-color: rgba(255, 255, 255, 1);

  @media screen and (max-width: 1280px) {
    padding: 16px 28px;
  }

  .header {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin-right: 8px;
  }

  tbody > tr {
    cursor: pointer;
  }
}

.row1 {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 15px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px !important;
  background-color: white;
  border: 2px solid #000;
}

.holdersFind {
  margin-left: 10px;
  width: 300px;
  border: none;

  border: #f9f9f9;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.holdersFind:focus {
  outline: none;
}

.idCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 865px) {
  .holdersFind {
    width: 100%;
  }
}
