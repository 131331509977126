@import 'styles/mixins';
@import '../styles/digitalAssetsCommon.module';

.walletWrapper {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px 40px;

  @media screen and (max-width: 395px) {
    padding: 16px 25px !important;
  }

  @media screen and (max-width: 340px) {
    padding: 10px !important;
  }
}

.Wallet {
  width: 100%;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //flex-wrap: wrap;

  @media screen and (max-width: 890px) {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  @media screen and (max-width: 600px) {
    padding: 20px;
  }
}

.secondRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 15px;
  }

  @media screen and (max-width: 395px) {
    align-items: flex-start;
  }
}

.modalText {
  padding: 20px 0;
}

.oneBlock {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  grid-template-areas: 'accountBox balanceBox buttonBox';
}

.oneRow {
  display: grid;
  grid-template-rows: 1fr;

  grid-template-areas: 'accountBox balanceBox buttonBox';
}

.balanceBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 260px;

  border: none !important;
  margin: 0 !important;

  > div {
    width: fit-content;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.balance {
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #212121;
}

.balanceWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;

  > div {
    margin: 0 !important;
  }

  @media screen and (max-width: 480px) {
    gap: 10px;
  }
}

.accountBox {
  display: flex;
  //align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-right: 10px;

  span {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 865px) {
  }
}

.btns {
  display: flex;
  gap: 10px;
}

.disconnectBox {
  grid-area: disconnectBox;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.WalletStatusBox {
  display: flex;
  align-items: center;
}

.buttonsBox {
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  align-items: end;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 890px) {
    flex-direction: row;
  }
}

@media screen and (max-width: 865px) {
  .Box {
    padding: 20px 20px;
  }

  .Wallet {
    //display: grid;
    //grid-template-columns: 1fr;
    //grid-template-rows: 60px 60px 60px 60px 60px 60px;
    //grid-template-areas:
    //        'accountBox'
    //        'balanceBox'
    //        'buttonBox'
  }

  .oneBlock {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  //.oneBlock, .accountBox, .balanceBox, .WalletStatusBox, .buttonsBox {
  //  display: flex;
  //  justify-content: center;
  //}

  .accountBox .Block {
    border: none;
  }
}
