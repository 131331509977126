.modalContainer {
  position: inherit;
  width: 100%;
  height: 100%;
  max-height: 60vh;
  transform: none;
  padding: 0;
  padding: 38px 38px;

  @media screen and (max-width: 1440px) {
    // max-width: 100% !important;
    // width: 70%;
    padding: 30px;
  }

  // @media screen and (max-width: 1200px) {
  //   width: 80%;
  // }

  // @media screen and (max-width: 767px) {
  //   width: 90%;
  // }

  // @media only screen and (min-aspect-ratio: 11/5) {
  //   width: 60%;
  // }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
    justify-content: space-between;
  }
}

.fullHeight {
  height: 100% !important;
  max-height: none !important;
}

.consentContainer {
  position: inherit;
  width: 100% !important;
  height: 60vh !important;
  max-height: 60vh !important;
  transform: none !important;
  padding: 0 !important;
  background-color: #e4e4e4 !important;

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
    justify-content: space-between;
  }
}

.closeIconWrapper,
.errorCloseIconWrapper,
.fullscreenIconWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: 0.2s;

  &:hover img {
    transform: scale(1.2);
  }
}

.iconsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.fullscreenIconWrapper {
  display: flex;
  margin-right: 13px;

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.closeIconWrapper {
  display: flex;
  align-self: center;
}

.mapContainer {
  width: 90%;
  height: 90%;
  padding: 24px;
}

.errorModalContainer {
  position: relative;
  border-radius: 12px;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.08);
  height: 50%;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    width: 70%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }

  .errorCloseIconWrapper {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

.passwordCloseIconWrapper {
  position: absolute;
  right: 31.5px;
  top: 23.5px;
  cursor: pointer;
}

.fullScreen {
  width: 65%;
  height: 80%;
  padding: 32px;

  @media screen and (max-width: 767px) {
    width: 90%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
    justify-content: space-between;
  }
}

.fullScreenActive {
  height: 100%;
  min-height: 90vh;
  padding: 0;
  background: transparent !important;
}
