.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  min-height: 72px;
  margin: 15px 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
