@import 'styles/mixins';
@import '../../styles/digitalAssetsCommon.module';

.BridgeHistoryTable {
}

.row1 {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;

  h2 {
    line-height: 20px !important;
  }
}

.filter {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 620px) {
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px !important;
  background-color: white;
  border: 2px solid #000;
  //box-shadow: 24px;
}

.transactionFind {
  margin-left: 10px;
  width: 300px;
  border: none;

  border: #f9f9f9;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.transactionFind:focus {
  outline: none;
}

.dataPicker {
  > div {
    input {
      flex-shrink: 0;
    }

    @media screen and (max-width: 620px) {
      width: 100%;
    }

    @media screen and (max-width: 500px) {
      width: 160px;

      input {
        width: 80px !important;
      }
    }

    @media screen and (max-width: 440px) {
      width: 140px;

      input {
        width: 90px !important;
        padding-left: 8px !important;
        padding-right: 0 !important;
      }

      > div > div {
        width: 110px !important;
      }
    }
  }
}

@media screen and (max-width: 865px) {
  .row1 {
    flex-direction: column;
  }

  .transactionFind {
    width: 100%;
  }
}
