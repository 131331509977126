@import 'styles/mixins';
@import '../styles/digitalAssetsCommon.module';

.Holders {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 14px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 24px 40px;
}

.Holders::-webkit-scrollbar {
  width: 0;
}

.row1 {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    font-size: 16px;
    font-weight: 600;
  }

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 20px;
    margin-bottom: 15px;
  }
}

.row2 {
  height: 60px;

  margin-bottom: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.img_button_caption {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-transform: none !important;

  color: #212121;
}

.img_button_caption_selected {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;

  text-transform: none !important;
  text-decoration: underline;

  color: #18397a;
}

.row3 {
}

@media screen and (max-width: 865px) {
  .header {
    font-size: 16px;
  }
}
