@import 'styles/mixins';

.DA {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 15px;
}

.DA .WO_AUTH.Box:last-child {
  height: 64vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
div,
td,
b,
strong,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.a,
.p,
.span,
.div,
.td,
.b,
.strong,
.button {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  //color: #212121;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  margin-bottom: 10px;
  //line-height: 36px;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 16px;
}

p,
.p {
  margin-bottom: 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

p:last-child,
.p:last-child {
  margin-bottom: 0;
}

link,
.link {
  text-decoration: underline;
  cursor: pointer;
  color: #18397a;
}

@mixin MuiButton-text {
  font-family: 'Montserrat', serif !important;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

/* ************************* */

.infoRow {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.infoRow:last-child {
  margin-bottom: 0;
}

.infoFieldName {
  width: 120px;
  margin-right: 10px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.infoFieldValue {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  word-break: break-all;
}
