@import 'styles/mixins';
@import '../../styles/digitalAssetsCommon.module';

.HoldersTable {
}

.row1 {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px !important;
  background-color: white;
  border: 2px solid #000;
  //box-shadow: 24px;
}

.holdersFind {
  margin-left: 10px;
  width: 300px;
  border: none;

  border: #f9f9f9;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.holdersFind:focus {
  outline: none;
}

@media screen and (max-width: 865px) {
  .row1 {
    flex-direction: column;
    align-items: flex-start;
  }

  .holdersFind {
    width: 100%;
  }
}

.transactionFind {
  width: 300px;
}

input.transactionFind {
  margin-left: 10px;
  width: 210px;
  border: none;

  border: #f9f9f9;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.transactionFind:focus {
  outline: none;
}

@media screen and (max-width: 350px) {
  .transactionFind {
    input {
      width: 150px;
    }
    width: 250px;
  }
}
