@import 'styles/mixins';

.tableContent {
  width: 100%;
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;

  @include for-mobile-max_767 {
    padding: 20px;
  }
}

.filters {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  row-gap: 12px;
  margin: 20px 0;
  padding: 0 60px;

  @include for-mobile-max_767 {
    padding: 0;
  }

  @include for-desktop-up-max_1500 {
    flex-wrap: wrap;
    margin-top: 0;
  }

  @media screen and (max-width: 970px) {
    flex-direction: column;
    width: 100%;

    > div {
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }

  .filterSelect {
    width: 257px;
    height: 48px;
    background: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 970px) {
      width: 100% !important;
    }
  }

  .searchFilter {
    @media screen and (max-width: 970px) {
      width: 100% !important;
    }

    height: 48px;
  }

  .menuItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .filterSelectPlaceholder {
    font-weight: 400;
    font-size: 16px;
    color: #555555;
  }
}
