@import 'styles/mixins';
@import '../../styles/digitalAssetsCommon.module';

.OperationDetailModal {
}

.context {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
}

.header {
  margin-bottom: 20px;
}

.transactionInfoRow {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.transactionInfoRow:last-child {
  margin-bottom: 0;
}

.transactionInfoFieldName {
  width: 120px;
  margin-right: 10px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.transactionInfoFieldValue {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}
