@import 'styles/mixins';

.pageContainer {
  @include for-desktop-up-min_1860 {
    margin: 10px 56px;
  }
  @include for-desktop-up-max_1860 {
    margin: 10px 56px;
  }
  @include for-desktop-up-max_1440 {
    margin: 10px 40px;
  }
  @include for-tablet-landscape-max_1024 {
    margin: 10px 24px;
  }
}

.imgBg {
  margin: 0;
}

.cookieWrapper {
  background: rgba(117, 117, 117, 0.5) !important;
  width: 750px !important;
  backdrop-filter: blur(15px) !important;
  border-radius: 6px;
  left: calc(50% - 375px) !important;
  bottom: 30px !important;
  align-items: center !important;

  @include for-tablet-landscape-max_1024 {
    left: calc(50% - 225px) !important;
    width: 450px !important;
    justify-content: center !important;
  }

  @include for-mobile-max_480 {
    border-radius: 0 !important;
    width: 100% !important;
    left: 0 !important;
    font-size: 14px !important;
  }
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.btn {
  border-radius: 6px !important;
  font-size: 16px !important;
  padding: 10px 36px !important;
  background: transparent !important;
  border: 1px solid #fff !important;
  transition: 0.25s !important;
  color: #fff !important;

  &:hover {
    background: #fff !important;
    border: 1px solid #fff !important;
    color: #333 !important;
  }

  @include for-mobile-max_480 {
    font-size: 14px !important;
  }
}
