.rightColumn {
  position: sticky;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 396px;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.widgetItem {
  margin-bottom: 16px;

  > div {
    width: 396px !important;
  }
}

.drawerWrapper {
  position: sticky !important;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 65px !important;
  background-color: #ffffff;
  border-radius: 12px;

  > div {
    width: 65px;
  }
}

.drawerOpen {
  position: absolute;
  width: 396px !important;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
}

.drawerList {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  margin: 0 5px;
}

.drawerItem {
  margin: 20px 15px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin: 0;
    white-space: normal !important;
  }
}

.cartIcon {
  position: relative;
  width: fit-content;
  height: fit-content;
  overflow: visible;

  .cartItemCounter {
    position: absolute;
    top: -3px;
    right: -5px;
    background-color: #143471;
    width: 22px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 3.5px;

    p {
      font-size: 8px;
      font-weight: 500;
      color: white;
    }
  }
}
