@import 'styles/mixins';

.row,
.rowMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  gap: 10px;
}

.row {
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: unset;
  }
}

.rowMain {
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: unset;
    gap: 30px;
  }
}

.pageContentBox {
  display: flex;
  width: 100%;
  flex-direction: column;
  //margin-top: 20px;
  //margin-left: 138px;
  //margin-right: 138px;
  background: #ffffff;
  border-radius: 12px;
  padding: 24px 60px;
  row-gap: 20px;

  @include for-desktop-up-max_1440 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  @include for-desktop-up-max_1200 {
    margin-top: 16px;
    padding: 24px 20px;
  }

  @include for-mobile-max_767 {
    margin-top: 12px;
    padding: 20px 10px;
    width: 100%;
  }
}

.connectContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  gap: 20px;
  background-color: #ffffff;
  border-radius: 12px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media screen and (max-width: 650px) {
    padding: 0;
    margin: 0;
  }
}

.accordionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04) !important;
  border-radius: 16px !important;
  padding: 20px 20px;
  overflow: hidden !important;
  margin: 0 !important;

  &:before {
    display: none;
  }
}

.accordionContent {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media screen and (max-width: 650px) {
    padding: 0;
  }

  @include for-mobile-max_767 {
    margin-top: 16px;
    row-gap: 16px;
  }
}

.contentDescription {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  //@include for-mobile-max_767 {
  //  font-size: 12px;
  //  line-height: 20px;
  //}
}

.contentInfoContainer {
  display: flex;
  column-gap: 60px;
  gap: 20px;
  align-items: center;

  @include for-tablet-landscape-max_900 {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  @media screen and (max-width: 650px) {
    padding: 0;
  }

  .contentInfoItem {
    display: flex;
    margin: 0;
    gap: 15px;
    width: 100%;

    @include for-tablet-landscape-max_900 {
      flex-direction: column;
      gap: 5px;
    }

    @media screen and (max-width: 650px) {
      padding: 0;
    }
  }
}

.contentInfoTitle,
.contentInfoDescription,
.selectChainTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.selectChainTitle {
  @media screen and (max-width: 650px) {
    display: none;
  }
}

.contentInfoDescription {
  font-weight: 400;
  word-break: break-all;
}

.borderButtonGroups {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 20px;

  @media screen and (max-width: 650px) {
    padding: 0;

    > button {
      width: 100%;
      max-width: 100%;
    }
  }

  //@include for-mobile-max_767 {
  //  row-gap: 16px;
  //}
}

.contentButton {
  //@include for-mobile-max_767 {
  //  padding: 0 18px !important;
  //}
}

.contentButtonText {
  font-style: normal;
  //font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;

  //@include for-tablet-landscape-max_900 {
  //  font-size: 12px;
  //}
}

.outlineButtonGroups,
.buttonGroups {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 20px;

  @media screen and (max-width: 650px) {
    padding: 0;

    > button {
      width: 100%;
      max-width: 100%;
      white-space: normal;
      height: 100%;
      padding: 10px;

      > div {
        word-break: break-word;
      }
    }
  }
}

.outlineButtonGroups {
  > button {
    border-color: #d32f2f !important;

    > div {
      color: #d32f2f !important;
    }
  }
}

.contentButton {
  display: flex;
  align-items: center;
  column-gap: 17px;

  //@include for-mobile-max_767 {
  //  padding: 0 18px;
  //}
}

.contentButtonText {
  font-style: normal;
  //font-weight: 700;
  font-size: 16px;
  //line-height: 30px;
  text-transform: uppercase;
  color: #18397a;

  //@include for-tablet-landscape-max_900 {
  //  font-size: 12px;
  //  line-height: 40px;
  //}
}

.warningButton {
  display: flex;
  align-items: center;
  border-radius: 4px;
  //padding: 0 20px !important;
  column-gap: 13px;
  width: fit-content;

  //@include for-mobile-max_767 {
  //  padding: 0 18px;
  //}
}

.warningButtonText {
  font-style: normal;
  //font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  //color: #d32f2f;

  //@include for-tablet-landscape-max_900 {
  //  font-size: 12px;
  //  line-height: 40px;
  //}
}

.form {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.formControlLabel {
  justify-content: center;
  margin-left: 5px;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #212121;
  text-transform: capitalize;
}

.modalTitle {
  margin-bottom: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
  width: 100%;
}

.modal_input {
  position: relative;
  width: 100%;
  text-transform: capitalize;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 16px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.formButtonGroup {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
  margin-top: 20px;
}

.accrdionTitle {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  font-weight: 600;
  font-size: 20px;

  > button {
    margin-right: 10px;
    cursor: pointer;
    background: none;
    border: none;
  }
}

.settingsModalAccordionTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;

  > span {
    font-size: 20px;
    font-weight: 600;
  }

  > div {
    cursor: pointer;
  }
}

.settingsModalAccordionContent {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.settingsModalAccordionContentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  > div,
  > input {
    flex-shrink: 1;
    width: unset;
  }

  > button {
    margin-left: 30px;
  }

  @media (max-width: 765px) {
    flex-direction: column;
    align-items: flex-start;

    > div {
      width: 100%;
    }

    > button {
      margin-left: 0;
    }
  }
}

.settingsModalAccordionContentBtns {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.settingsModalAccordionContentBtnsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.fieldModalAccordion {
  padding: 16px 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #efefef;

  &:before {
    display: none !important;
  }
}

.fieldModalAccordionTitle {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.fieldModalAccordionContent {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}

.fieldModalAccordionRow,
.fieldModalAccordionSelectRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  > input {
    width: unset;
  }
}

.fieldModalAccordionSelectRow {
  > div,
  > input {
    width: 100%;
  }
}

.rowCheckbox {
  margin-left: 1px !important;
  gap: 8px !important;
  color: #143471 !important;
}

.result {
  font-size: 12pt;
}

.ContractFieldList {
  margin: 0 !important;
}

.ContractFieldList_Checkbox {
  margin: 0 !important;
  .MuiFormControl-root {
    width: 100%;
  }
}

.compileButton {
  @media screen and (max-width: 650px) {
    width: 100%;
    max-width: 100%;
  }
}

.tabs {
  > div {
    margin: 0 !important;
  }
}
