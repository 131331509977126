.page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-bottom: 30px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;

    @media screen and (max-width: 540px) {
      font-size: 16px;
    }
  }
}

.certLoadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
}

.block {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 540px) {
    padding: 24px 20px;
  }
}

.blockHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  > h2,
  > div {
    font-size: 24px;
    font-weight: 600;

    @media screen and (max-width: 540px) {
      font-size: 18px;
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 540px) {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.mainBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h2 {
    font-size: 20px;
    font-weight: 600;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.flexRow,
.modalRow,
.saveRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.saveRow {
  @media screen and (max-width: 540px) {
    flex-direction: column-reverse;
    gap: 10px;

    > button {
      width: 100%;
      max-width: 100%;
    }
  }
}

.flexRow {
  gap: 10px;

  > button {
    flex-shrink: 0 !important;
  }

  > div {
    width: 100%;
  }

  @media screen and (max-width: 540px) {
    flex-direction: column;
    gap: 10px;

    > div,
    > button {
      width: 100%;
      max-width: 100%;
    }
  }
}

.tableWrapper {
  border-radius: 12px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 4px 16px 0 #0000000f;
  position: relative;
  padding: 20px 20px 30px;
}

.tableBottomTooltip {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #2d8342;
  color: #ffffff;
  padding: 8px 16px 8px 16px;
  border-radius: 0 12px 0 12px;
}

.entryModalGrid {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;
  margin-bottom: 22px;

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    > div {
      width: 100%;
    }

    @media screen and (max-width: 540px) {
      flex-direction: column;
    }
  }
}
