@import 'styles/mixins';
@import '../styles/digitalAssetsCommon.module';

.Explorer {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 55vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Explorer.WO_AUTH {
  height: 64vh;
}

.Explorer::-webkit-scrollbar {
  width: 0;
}

.row1 {
  height: 60px;

  margin-bottom: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.row2 {
  height: 60px;

  margin-bottom: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.img_button_caption {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-transform: none !important;

  color: #212121;
}

.img_button_caption_selected {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;

  text-transform: none !important;
  text-decoration: underline;

  color: #18397a;
}

.row3 {
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px !important;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
}

@media screen and (max-width: 865px) {
  .header {
    font-size: 16px;
  }
}
