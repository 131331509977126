@import 'styles/mixins';
@import '../../styles/digitalAssetsCommon.module';

.TransactionHistoryTable {
  padding: 24px 40px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 14px;

  @media screen and (max-width: 470px) {
    padding: 10px 5px;
  }
}

.row1 {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.searchRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  > * {
    margin: 0 !important;
  }

  @media screen and (max-width: 865px) {
    margin-top: 5px;
  }

  @media screen and (max-width: 645px) {
    margin-top: 10px;
    flex-direction: column;
    gap: 10px;

    > span,
    > div {
      width: 100% !important;
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px !important;
  background-color: white;
  border: 2px solid #000;
  //box-shadow: 24px;
}

.transactionFind {
  width: 300px;
}

input.transactionFind {
  margin-left: 10px;
  width: 210px;
  border: none;

  border: #f9f9f9;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.transactionFind:focus {
  outline: none;
}

@media screen and (max-width: 865px) {
  .row1 {
    flex-direction: column;
  }

  .transactionFind {
    width: 100%;
  }
}
