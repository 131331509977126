@import 'styles/mixins';

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  @include for-mobile-max_767 {
    flex-wrap: wrap;
  }
}

.withoutZIndex {
  z-index: 1;
}

.logo {
  position: relative;
  width: 348px;
  height: 80px;
  z-index: 10;

  @include for-mobile-max_767 {
    width: 180px;
    height: 40px;
  }
}

.logoImg {
  width: 100%;
  height: 100%;
}

.headerNav {
  display: flex;
  z-index: inherit;
}

.hidden {
  @include for-mobile-max_767 {
    position: absolute;
    top: 20px;
    right: 20px;
    width: max-content;
    z-index: 1;
  }

  & > .logo {
    @include for-mobile-max_767 {
      display: none;
    }
  }
}

.signHeader {
  position: sticky;
  top: 0;
  transition: 0.45s;

  @include for-desktop-up-min_1860 {
    padding: 10px 56px;
  }
  @include for-desktop-up-max_1860 {
    padding: 10px 56px;
  }
  @include for-desktop-up-max_1440 {
    padding: 10px 24px;
  }
}

@-webkit-keyframes scrollUp {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scrollUp {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes scrollDown {
  100% {
    background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
    backdrop-filter: blur(4px);
    opacity: 1;
  }

  0% {
    background: transparent;
    backdrop-filter: blur(0px);
    opacity: 0;
  }
}

@keyframes scrollDown {
  100% {
    background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
    backdrop-filter: blur(4px);
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.scrolled {
  &::after {
    content: '';
    position: absolute;
    left: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
    backdrop-filter: blur(4px);
    z-index: 0;
    -webkit-animation: scrollUp 0.3s;
    animation: scrollUp 0.3s;
  }
}
