@import 'styles/mixins';
@import '../styles/digitalAssetsCommon.module';

.TokenizationAssets {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 55vh;
  margin-top: 14px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.TokenizationAssets::-webkit-scrollbar {
  width: 0;
}

.row1 {
  height: 60px;

  margin-bottom: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.row2 {
  height: 60px;

  margin-bottom: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.img_button_caption {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-transform: none !important;

  color: #212121;
}

.img_button_caption_selected {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;

  text-transform: none !important;
  text-decoration: underline;

  color: #18397a;
}

@media screen and (max-width: 865px) {
  .header {
    font-size: 16px;
  }
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 14px;
  background-color: transparent !important;

  > div:nth-child(2) {
    flex-shrink: 0;

    @media screen and (max-width: 620px) {
      flex-shrink: 1 !important;
      width: 100%;
    }
  }

  @media screen and (max-width: 1280px) {
    gap: 12px;
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;
  }

  .searchFilter {
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
  }

  .topSelect {
    width: 100%;
    min-width: 250px;
    height: 47px;
    max-width: 292px;

    @media screen and (max-width: 620px) {
      max-width: 100% !important;
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  .topMenuItem {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    text-transform: capitalize;
  }

  .sort {
    min-width: 100px;
    width: 100%;
    max-width: 110px;
    display: flex;
    justify-content: flex-end;

    > div {
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      margin-right: 10px;

      span {
        white-space: nowrap;
      }
    }
  }
}

.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  min-height: 72px;
  margin: 15px 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
