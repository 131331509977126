@import '../../../../../styles/mixins';
@import '../../../styles/digitalAssetsCommon.module';

.сarbonTransactionDetailModal {
}

.h1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #212121;
}

.row1 {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  margin-bottom: 20px;
}

.row1_1 {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: flex-start;
  gap: 25px;
}

.col1_1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.certDownload {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b333f;
  opacity: 0.3;

  position: absolute;
  right: 60px;
  width: 346px;
  height: 260px;

  border-radius: 5px;
}

.certDownloadSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b333f;
  opacity: 0.3;

  position: absolute;
  top: 400px;
  width: 300px;
  height: 30px;

  border-radius: 5px;
}

.certMainDownloadSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b333f;
  opacity: 0.3;

  position: absolute;
  top: 900px;
  width: 300px;
  height: 30px;

  border-radius: 5px;
}

@media screen and (max-width: 865px) {
  .сarbonTransactionDetailModal {
    width: 98%;
  }

  .row1 {
    flex-direction: column;
  }
}
