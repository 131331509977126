@import 'styles/mixins';
@import '../styles/digitalAssetsCommon.module';

.Bridge {
  overflow-x: hidden;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 14px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 20px 40px;
}

.Bridge::-webkit-scrollbar {
  width: 0;
}

.loaderWrapper {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row1 {
  margin-bottom: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  button {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media screen and (max-width: 768px) {
    > div:nth-child(1) {
      margin-top: 0 !important;
      border: 1px solid #cccccc !important;
      border-radius: 12px;
    }
  }
}

.row2 {
  height: 60px;

  margin-bottom: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.img_button_caption {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-transform: none !important;

  color: #212121;
}

.img_button_caption_selected {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;

  text-transform: none !important;
  text-decoration: underline;

  color: #18397a;
}

.row3 {
  display: flex;
  flex-direction: column;
}

.sendRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.sendBlock {
  margin-top: 20px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }

  button {
    @media screen and (max-width: 400px) {
      font-size: 14px !important;
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px !important;
  background-color: white;
  border: 2px solid #000;
  //box-shadow: 24px;
}

@media screen and (max-width: 865px) {
  .header {
    font-size: 16px;
  }
}

.sendToRow2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.confirmDetailRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  gap: 30px;
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.confirmDetailRow div:first-child {
  width: 250px;
}

.confirmDetailRow div:last-child {
  width: 486px;
}

.tabs {
  width: 180px;
}

@media screen and (max-width: 865px) {
  .confirm .confirmDetailRow {
    display: block;
  }

  .confirmDetailRow div:first-child {
    width: 100%;
  }

  .confirmDetailRow div:last-child {
    width: 100%;
  }

  .confirm .confirmDetailRow .recipientAddress {
    font-size: 9px;
  }
}

.receiveSelectBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  margin-top: 30px;
  width: 500px;

  label {
    position: absolute;
    top: -22px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
  }
}

.receiveSelect {
  width: 400px;

  fieldset {
    border-radius: 12px !important;
  }

  @media screen and (max-width: 450px) {
    width: 300px;
  }

  @media screen and (max-width: 350px) {
    width: 260px;
  }
}
